import Badge from 'react-bootstrap/Badge'
import Table from 'react-bootstrap/Table';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import {
    selectHistoryWorkouts,
} from './historySlice'

import { useSelector } from 'react-redux'

const ExcerciseChart = ({excerciseKey, workouts}) => {
    const dataPoints = [];
    for (let workoutKey in workouts) {
        const workout = workouts[workoutKey];
        const excercise = workout.excercises.filter((item) => item.id === excerciseKey)[0];
        if (excercise === undefined) {
            continue;
        }

        var start = undefined;
        var finish = undefined;
        var weight = 0;
        for (let rep of excercise.reps) {
            if (rep.toggledAt !== undefined) {

                if (rep.type === 'warmup' && (start === undefined || start < rep.toggledAt)) {
                    start = rep.toggledAt;
                }

                if (rep.type !== 'warmup' && (start === undefined || start > rep.toggledAt)) {
                    start = rep.toggledAt;
                }

                if (finish === undefined || finish < rep.toggledAt) {
                    finish = rep.toggledAt;
                }
            }

            if (rep.finishTimestamp !== undefined) {
                if (rep.type === 'warmup' && (start === undefined || start < rep.finishTimestamp)) {
                    start = rep.startTimestamp;
                }
                if (finish === undefined || finish < rep.finishTimestamp) {
                    finish = rep.finishTimestamp;
                }
            }

            if (rep.startTimestamp !== undefined) {
                if (rep.type !== 'warmup' && (start === undefined || start > rep.startTimestamp)) {
                    start = rep.startTimestamp;
                }
            }

            if (rep.type === 'warmup') {
                continue;
            }

            weight += rep.weight * rep.done;
        }
        if (start === undefined || finish === undefined) {
            continue;
        }
        const delta = (finish - start) / 1000;
        if (delta === 0) {
            continue;
        }
        const kgPerSecond = weight / delta;
        if (kgPerSecond > 100) {
            continue;
        }
        // dataPoints.push([weight, (finish - start) / 60000]);
        dataPoints.push({x: start, y: kgPerSecond});
    }

    const options = {
        title: {
            text: 'Time to weight'
        },
        xAxis: {
            type: 'datetime',
            title: {
                text: 'date',
            },
        },
        yAxis: {
            title: {
                text: 'kg/sec',
            },
            labels: {
                format: '{value:.2f}',
            },
        },
        chart: {
            type: 'scatter',
            // backgroundColor: 'rgba(255, 255, 255, 0)',
        },
        series: [{
            data: dataPoints,
        }]
    };

    return (
        <>
            <h2>{excerciseKey}</h2>
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
              />
        </>
    );
}

const Analytics = () => {
    const workouts = useSelector(selectHistoryWorkouts);
    const excercises = {};

    for (let workout of Object.values(workouts)) {
        for (let excercise of workout.excercises) {
            excercises[excercise.id] = excercise;
        }
    }
    const excercisesKeys = Object.keys(excercises).toSorted();
    const charts = [];
    for (let excercise of excercisesKeys) {
        charts.push(
            <ExcerciseChart key={excercise} excerciseKey={excercise} workouts={workouts} />
        );
    }
    return (<>{charts}</>);
}

export default Analytics
