import {
    createSelector,
    createSlice,
} from '@reduxjs/toolkit'

const initialState = {
    workouts: {},
    id: undefined,
};


const historySlice = createSlice({
    name: 'history',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(
            'currentWorkout/finished',
            (state, action) => {
                const {
                    type,
                    started,
                    excercises,
                } = action.payload;
                state.workouts[started] = {
                    type,
                    started,
                    excercises,
                };
                state.id = started;
            }
        )
    }
})

export const selectHistoryWorkouts = (state) => (state.history.workouts);

export const selectHistoryStats = createSelector(
    selectHistoryWorkouts,
    (history) => {
        const result = {
            lastWorkoutType: 'A',
            lastExcercise: {},
            lastSuccessExcercise: {},
            bestExcercise: {},
            history: {},
        };
        const keys = Object.keys(history);
        // TODO sort numericaly
        keys.sort();
        for (let id of keys) {
            const workout = history[id];
            result.lastWorkoutType = workout.type;
            for (let excercise of workout.excercises) {
                let isSuccess = true;
                for (let rep of excercise.reps) {
                    if (rep.type === 'warmup') {
                        continue;
                    }
                    if (rep.done !== rep.target) {
                        isSuccess = false;
                        break;
                    }
                }
                const info = {
                    name: excercise.name,
                    weight: excercise.targetWeight,
                    count: 1,
                    dateTime: workout.started,
                    success: isSuccess,
                };
                result.lastExcercise[excercise.id] = info;
                if (isSuccess) {
                    result.lastSuccessExcercise[excercise.id] = info;
                    if (
                        result.bestExcercise[excercise.id] === undefined
                        || result.bestExcercise[excercise.id].weight < excercise.targetWeight
                    ) {
                        result.bestExcercise[excercise.id] = info;
                    } else if (
                        result.bestExcercise[excercise.id].weight === excercise.targetWeight
                    ) {
                        // console.log('xxxx',  result.bestExcercise[excercise.id].count);
                        result.bestExcercise[excercise.id].count += 1;
                    }
                }
                if (result.history[excercise.id] === undefined) {
                    result.history[excercise.id] = [];
                }
                result.history[excercise.id].push(info);
            }
        }
        return result;
    }
);

/*
export const selectCurrentWorkout = (state) => (state.currentWorkout);

export const selectExcercises = createSelector(
    selectCurrentWorkout,
    (state) => (state.excercises),
);

export const selectExcerciseIds = createSelector(
    selectExcercises,
    (excercises) => excercises.map((excercise) => excercise.id)
)

export const selectExcercise = (id) => {
    return createSelector(
        selectExcercises,
        (excercises) => (excercises.filter(item => item.id === id)[0]),
    );
}
*/

export default historySlice.reducer
