import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { useSelector, useDispatch } from 'react-redux'
import {
    finished,
    cancelled,
    selectCurrentWorkout,
    selectExcerciseIds,
} from './currentWorkoutSlice'

import Excercise from './Excercise'


const CurrentWorkout = () => {
    const workout = useSelector(selectCurrentWorkout);
    const excercisesIds = useSelector(selectExcerciseIds);
    const dispatch = useDispatch();

    const finish = (e) => {
        e.preventDefault();
        dispatch(finished(workout));
        dispatch({type: 'history/finished', payload: workout});
    }

    const cancel = (e) => {
        e.preventDefault();
        dispatch(cancelled());
    }

    const excercises = excercisesIds.map(
        (id) => {
            return (
                <Excercise id={id} key={id} />
            );
        }
    );

    return (
        <>
            <h2>Workout {workout.type}</h2>
            {excercises}
            <Row className="justify-content-md-center">
              <Col md="auto">
                <Button onClick={finish}>Finish</Button>
                &nbsp;
                <Button onClick={cancel}>Cancel</Button>
              </Col>
            </Row>
        </>
    );
}

export default CurrentWorkout
