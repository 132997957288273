import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    started,
} from '../excercises/currentWorkoutSlice'
import {
    selectHistoryStats,
} from '../history/historySlice'


const WorkoutStarter = () => {
    const dispatch = useDispatch();
    const stats = useSelector(selectHistoryStats);

    const start = (type) => {
        return (e) => {
            e.preventDefault();
            dispatch(started({type: type, started: Date.now(), stats: stats}))
        }
    }
    const keys = Object.keys(stats.lastSuccessExcercise);
    const excercises = [];
    keys.sort();
    for (let key of keys) {
        const ex = stats.bestExcercise[key];
        const dateStr = new Date(ex.dateTime).toLocaleDateString('ru-RU')
        excercises.push(
            <Col md="auto" key={key}>
                {ex.name}<br />
                {ex.weight} kg<br />
                {dateStr}
            </Col>
        );
    }

    return (
        <>
            <h2>Start new workout</h2>
            <Row className="justify-content-md-center">
                <Col md="auto">
                    Previous workout: {stats.lastWorkoutType}
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                {excercises}
            </Row>
            <Row className="justify-content-md-center">
              <Col md="auto">
                <Button onClick={start('A')}>Start A</Button>
                &nbsp;
                <Button onClick={start('B')}>Start B</Button>
              </Col>
            </Row>
        </>
    );
}

export default WorkoutStarter
