import {
  NavLink,
  Outlet
} from 'react-router-dom'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Row from 'react-bootstrap/Row'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';

function App() {
  return (
    <Container style={{maxWidth: '768px'}}>
      <Row>
        <Col>
          <Navbar>
            <Nav>
              <Nav.Link as={NavLink} to="/">Home</Nav.Link>
              <Nav.Link as={NavLink} to="/history">History</Nav.Link>
            </Nav>
          </Navbar>
        </Col>
      </Row>
      <Outlet />
    </Container>
  );
}

export default App;

// vim: set ts=2 sw=2
