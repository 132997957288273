import {
    createSelector,
    createSlice,
} from '@reduxjs/toolkit'

const initialState = {
    status: 'new',
    type: 'A',
    started: undefined,
    finished: undefined,
    stats: {},
    excercises: [],
};

const max = (x, y) => {return x > y ? x : y};
const min = (x, y) => {return x < y ? x : y};

const getAllPlatesConfig = (targetWeight, configuration, maxDepth) => {
    const result = [];

    if (maxDepth <= 0 || configuration.length === 0) {
        return result;
    }
    for (let i in configuration) {
        const weight = configuration[i];
        if (weight > targetWeight) {
            continue;
        }
        else if (weight === targetWeight) {
            result.push([weight]);
        }
        else { // weight < targetWeight
            const subConfigurations = getAllPlatesConfig(
                targetWeight - weight,
                configuration.slice(i),
                maxDepth - 1
            );
            for (let subconf of subConfigurations) {
                result.push([weight].concat(subconf));
            }
        }
    }
    return result;
}

const makeReps = (id, targetWeight) => {
    const emptyWeight = 20;
    const targetWarmupsReps = 4;
    const mainReps = (id !== 'deadlift') ? 5 : 1;
    const firstWarmupReps = 10;
    const warmupReps = (id !== 'deadlift') ? 3 : 5;
    const mainRepsTarget = 5;
    const bestRepsForWarmup = 3;

    const platesConfig = getAllPlatesConfig(
        (targetWeight - emptyWeight) / 2,
        [25, 20, 15, 10, 5, 2.5, 1.25],
        // [20, 15, 10, 5, 2.5, 1.25],
        7,
    );
    for (let cfg of platesConfig) {
        // Get penalties for config
        const maxValue = Math.max(...cfg);
        let penalty = 0;
        let counter = {};
        let e0 = 0.0;
        let e1 = 0.0;
        let mainWeightsCount = 0;

        for (let weight of cfg) {
            if (weight in counter) {
                counter[weight] += 1;
            } else {
                counter[weight] = 1;
            }

            if (weight <= 1.25) {
                continue;
            }

            mainWeightsCount += 1;
            e0 += weight;
        }
        if (mainWeightsCount > 0) {
            e0 /= mainWeightsCount;
        }
        for (let weight of cfg) {
            if (weight <= 1.25) {
                continue;
            }
            e1 += (weight - e0) * (weight - e0);
        }
        if (mainWeightsCount > 0) {
            e1 /= mainWeightsCount;
        }

        for (let freq of Object.values(counter)) {
            if (freq > 1) {
                penalty += freq * freq;
            }
        }
        if (counter[1.25] !== undefined && counter[1.25] > 1) {
            penalty += 50;
        }

        if (maxValue >= 25) {
            penalty += 5;
        }

        if (mainWeightsCount < bestRepsForWarmup) {
            penalty += 100 - mainWeightsCount;
        } else if (cfg.length > bestRepsForWarmup) {
            penalty += mainWeightsCount - (bestRepsForWarmup + 1);
        }

        penalty += Math.sqrt(e1);

        // cfg.push(e1);
        // cfg.push(Math.max(...cfg));
        // console.log(e1, cfg);
        cfg.push(penalty);
    }
    platesConfig.sort((a, b) => {
        // Compare by penalties
        return a[a.length - 1] - b[b.length - 1];
        /*
        const av = a.length < bestRepsForWarmup + 2 ? 1000 - a.length : a.length - (bestRepsForWarmup + 2);
        const bv = b.length < bestRepsForWarmup + 2 ? 1000 - a.length : b.length - (bestRepsForWarmup + 2);
        if (av === bv) {
            return a[a.length - 1] - b[b.length - 1];
        }
        return av - bv;
        */
    });
    // console.log(platesConfig);

    const currentPlatesConfig = [];
    const result = [];

    if (platesConfig.length > 0) {
        const bestPlatesConfig = [...platesConfig[0].slice(0, platesConfig[0].length - 1)];
        let currentWeight = min(emptyWeight, targetWeight);
        while (bestPlatesConfig.length > 0 && result.length <= bestRepsForWarmup) {
            const reps = result.length === 0 ? firstWarmupReps : warmupReps;
            result.push({
                id: result.length,
                target: reps,
                done: 0,
                weight: currentWeight,
                type: 'warmup',
                startTimestamp: undefined,
                finishTimestamp: undefined,
                plates: [...currentPlatesConfig],
            });
            const weight = bestPlatesConfig.shift();
            currentWeight += weight * 2;
            currentPlatesConfig.push(weight);
        }
        currentPlatesConfig.push(...bestPlatesConfig);
    } else {
        let currentWeight = min(emptyWeight, targetWeight);
        const weightStep = max(
            10,
            Math.ceil((targetWeight - currentWeight) / targetWarmupsReps / 10) * 10
        );
        result.push({
            id: 0,
            target: firstWarmupReps,
            done: 0,
            weight: currentWeight,
            type: 'warmup',
            startTimestamp: undefined,
            finishTimestamp: undefined,
            platesConfig: [...currentPlatesConfig],
        });
        while (currentWeight + weightStep < targetWeight) {
            currentWeight += weightStep;
            result.push(
                {
                    id: result.length,
                    target: warmupReps,
                    done: 0,
                    weight: currentWeight,
                    type: 'warmup',
                    startTimestamp: undefined,
                    finishTimestamp: undefined,
                }
            );
        }
    }

    for (let i = 0; i < mainReps; i++) {
        result.push(
            {
                id: result.length,
                target: mainRepsTarget,
                done: 0,
                weight: targetWeight,
                type: 'main',
                startTimestamp: undefined,
                finishTimestamp: undefined,
                plates: [...currentPlatesConfig],
            }
        );
    }
    return result;
}

const makeExcercise = (id, stats, targetWeight) => {
    const names = {
        'squat': 'Squat',
        'bench': 'Bench Press',
        'barbellrow': 'Barbell Row',
        'overheadpress': 'Overhead Press',
        'deadlift': 'Dead Lift',
    };
    // TODO calculate from history
    if (targetWeight === undefined) {
        if (id in stats.lastSuccessExcercise) {
            targetWeight = stats.lastSuccessExcercise[id].weight + 2.5;
        } else {
            targetWeight = 20;
        }
    }
    return {
        id: id,
        name: names[id],
        targetWeight: targetWeight,
        reps: makeReps(id, targetWeight),
    };
}


const makeExcercises = (type, stats) => {
    const result = [];
    if (type === 'A') {
        result.push(makeExcercise('squat', stats));
        result.push(makeExcercise('bench', stats));
        result.push(makeExcercise('barbellrow', stats));
    } else if (type === 'B') {
        result.push(makeExcercise('squat', stats));
        result.push(makeExcercise('overheadpress', stats));
        result.push(makeExcercise('deadlift', stats));
    } else {
        result.push(makeExcercise('squat', stats));
    }
    return result;
}


const currentWorkoutSlice = createSlice({
    name: 'currentWorkout',
    initialState: initialState,
    reducers: {
        started(state, action) {
            if (state.status === 'inProgress') {
                return state;
            }
            const result = {
                status: 'inProgress',
                type: action.payload.type,
                started: action.payload.started,
                stats: action.payload.stats,
                excercises: makeExcercises(action.payload.type, action.payload.stats),
            };
            return result;
        },
        finished(state, action) {
            state.status = 'new'
        },
        cancelled(state, action) {
            state.status = 'new'
        },
        repToggled(state, action) {
            const payload = action.payload;
            for (let i in state.excercises) {
                const excercise = state.excercises[i];
                if (excercise.id !== payload.excerciseId) {
                    continue;
                }
                for (let rep of excercise.reps) {
                    if (rep.id !== payload.repId) {
                        continue;
                    }
                    if (rep.startTimestamp === undefined) {
                        rep.startTimestamp = payload.toggledAt;
                        rep.done = 0;
                        rep.finishTimestamp = undefined;
                    } else if (
                        rep.startTimestamp !== undefined && rep.finishTimestamp === undefined
                    ) {
                        rep.finishTimestamp = payload.toggledAt;
                        rep.done = rep.target;
                    } else if (
                        rep.startTimestamp !== undefined && rep.finishTimestamp !== undefined
                    ) {
                        rep.done = rep.done - 1;
                        if (rep.done <= 0) {
                            rep.startTimestamp = undefined;
                            rep.finishTimestamp = undefined;
                            rep.done = 0;
                        }
                    }
                }
            }
        },
        weightChanged(state, action) {
            const payload = action.payload;
            for (let i in state.excercises) {
                const excercise = state.excercises[i];
                if (excercise.id !== payload.excerciseId) {
                    continue;
                }
                state.excercises[i] = makeExcercise(
                    excercise.id,
                    [],
                    payload.weight,
                );
            }
        }
    },
})

export const {
    started,
    finished,
    cancelled,
    repToggled,
    weightChanged,
} = currentWorkoutSlice.actions


export const selectCurrentWorkout = (state) => state.currentWorkout

export const selectExcercises = createSelector(
    selectCurrentWorkout,
    (state) => (state.excercises),
);

export const selectExcerciseIds = createSelector(
    selectExcercises,
    (excercises) => excercises.map((excercise) => excercise.id)
)

export const selectStats = createSelector(
    selectCurrentWorkout,
    (workout) => workout.stats,
)

export const selectExcercise = createSelector(
    [
        selectExcercises,
        (state, id) => id,
    ],
    (excercises, id) => excercises.filter(item => item.id === id)[0],
);

/*
import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit'
import { client } from '../../api/client'
import { StatusFilters } from '../filters/filtersSlice'

const todosAdapter = createEntityAdapter()

const initialState = todosAdapter.getInitialState({
  status: 'idle',
})

// Thunk functions
export const fetchTodos = createAsyncThunk('todos/fetchTodos', async () => {
  const response = await client.get('/fakeApi/todos')
  return response.todos
})

export const saveNewTodo = createAsyncThunk(
  'todos/saveNewTodo',
  async (text) => {
    const initialTodo = { text }
    const response = await client.post('/fakeApi/todos', { todo: initialTodo })
    return response.todo
  }
)

const todosSlice = createSlice({
  name: 'todos',
  initialState,
  reducers: {
    todoToggled(state, action) {
      const todoId = action.payload
      const todo = state.entities[todoId]
      todo.completed = !todo.completed
    },
    todoColorSelected: {
      reducer(state, action) {
        const { color, todoId } = action.payload
        state.entities[todoId].color = color
      },
      prepare(todoId, color) {
        return {
          payload: { todoId, color },
        }
      },
    },
    todoDeleted: todosAdapter.removeOne,
    allTodosCompleted(state, action) {
      Object.values(state.entities).forEach((todo) => {
        todo.completed = true
      })
    },
    completedTodosCleared(state, action) {
      const completedIds = Object.values(state.entities)
        .filter((todo) => todo.completed)
        .map((todo) => todo.id)
      todosAdapter.removeMany(state, completedIds)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTodos.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchTodos.fulfilled, (state, action) => {
        todosAdapter.setAll(state, action.payload)
        state.status = 'idle'
      })
      .addCase(saveNewTodo.fulfilled, todosAdapter.addOne)
  },
})

export const {
  allTodosCompleted,
  completedTodosCleared,
  todoAdded,
  todoColorSelected,
  todoDeleted,
  todoToggled,
} = todosSlice.actions

export default todosSlice.reducer

export const {
  selectAll: selectTodos,
  selectById: selectTodoById,
} = todosAdapter.getSelectors((state) => state.todos)

export const selectTodoIds = createSelector(
  // First, pass one or more "input selector" functions:
  selectTodos,
  // Then, an "output selector" that receives all the input results as arguments
  // and returns a final result value
  (todos) => todos.map((todo) => todo.id)
)

export const selectFilteredTodos = createSelector(
  // First input selector: all todos
  selectTodos,
  // Second input selector: all filter values
  (state) => state.filters,
  // Output selector: receives both values
  (todos, filters) => {
    const { status, colors } = filters
    const showAllCompletions = status === StatusFilters.All
    if (showAllCompletions && colors.length === 0) {
      return todos
    }

    const completedStatus = status === StatusFilters.Completed
    // Return either active or completed todos based on filter
    return todos.filter((todo) => {
      const statusMatches =
        showAllCompletions || todo.completed === completedStatus
      const colorMatches = colors.length === 0 || colors.includes(todo.color)
      return statusMatches && colorMatches
    })
  }
)

export const selectFilteredTodoIds = createSelector(
  // Pass our other memoized selector as an input
  selectFilteredTodos,
  // And derive data in the output selector
  (filteredTodos) => filteredTodos.map((todo) => todo.id)
)
*/
export default currentWorkoutSlice.reducer
