import { useSelector } from 'react-redux'
import WorkoutStarter from './WorkoutStarter'
import CurrentWorkout from '../excercises/CurrentWorkout'
import {
    selectCurrentWorkout,
} from '../excercises/currentWorkoutSlice'


const MainPage = () => {
    const workout = useSelector(selectCurrentWorkout);

    if (workout.status === 'new') {
        return <WorkoutStarter />
    }
    return <CurrentWorkout />;
}

export default MainPage
