import axios from 'axios'
import Cookies from 'js-cookie'
import { customAlphabet } from 'nanoid'


class KvStorage {

    constructor(url) {
        this.url = url;
        let key = Cookies.get('kvstorage');
        if (key === undefined || key === null || key === '') {
            const nanoid = customAlphabet(
                'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM0123456789',
                64
            );
            key = nanoid();
            Cookies.set('kvstorage', key, {
                expires: 365 * 10,
            });
        }
        this.userKey = key;
        this.urlPrefix = `${this.url}/0/site/${window.location.host}/${this.userKey}`;
    }

    getItem(key) {
        return axios.get(
            `${this.urlPrefix}/${key}`,
            {
                responseType: 'text',
                crossDomain: true,
            },
        ).then(
            (result) => {
                return result.data;
            }
        )
    }

    setItem(key, value) {
        return axios.put(
            `${this.urlPrefix}/${key}`,
            value,
            {
                crossDomain: true,
            },
        )
    }

    removeItem(key) {
        return axios.delete(
            `${this.urlPrefix}/${key}`,
            '',
            {
                crossDomain: true,
            },
        )
    }

};


export default KvStorage;
