import Badge from 'react-bootstrap/Badge'
import Table from 'react-bootstrap/Table';

import {
    selectHistoryWorkouts,
} from './historySlice'

import { useSelector } from 'react-redux'


const History = () => {
    const workouts = useSelector(selectHistoryWorkouts);
    const keys = Object.keys(workouts).toSorted((a, b) => (a - b));
    const excercises = {};

    for (let workout of Object.values(workouts)) {
        for (let excercise of workout.excercises) {
            excercises[excercise.id] = excercise;
        }
    }
    const excercisesKeys = Object.keys(excercises).toSorted();

    const head = [
        <tr>
            <th>Date</th>
            <th>Type</th>
        {
            excercisesKeys.map((key) => (
                <th key={`header-${key}`}>
                    {excercises[key].name}
                </th>
            ))
        }
        </tr>
    ];
    const body = [];
    for (let key of keys) {
        const workout = workouts[key];
        const date = new Date(workout.started)
        const cols = [];
        for (let exKey of excercisesKeys) {
            const excercise = workout.excercises.filter((item) => item.id === exKey)[0];
            let content = null;
            if (excercise !== undefined) {
                let isSuccess = true;
                for (let rep of excercise.reps) {
                    if (rep.type === 'warmup') {
                        continue;
                    }
                    if (rep.done !== rep.target) {
                        isSuccess = false;
                        break;
                    }
                }
                content = (
                    <Badge bg={isSuccess ? 'success' : 'danger'}>
                        {excercise.targetWeight}
                    </Badge>
                );
            }
            cols.push(
                <td key={`excercise-${exKey}`}>
                    {content}
                </td>
            );
        }
        body.push(
            <tr key={key}>
                <td>{date.toLocaleDateString('ru-RU')}</td>
                <td><strong>{workout.type}</strong></td>
                {cols}
            </tr>
        );
    }

    return (
        <>
            <h2>History</h2>
            <Table>
                <thead>{head}</thead>
                <tbody>{body}</tbody>
            </Table>
        </>
    );
}

export default History
