import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import {
  createHashRouter,
  RouterProvider,
} from 'react-router-dom'

import './index.css'
import App from './App'
import { PersistGate } from 'redux-persist/integration/react'

import MainPage from './features/main/MainPage'
import History from './features/history/History'
import Analytics from './features/history/Analytics'
import { store, persistor } from './store/configureStore.js'


const router = createHashRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        element: <MainPage />,
      },
      {
        path: 'history',
        element: <History />,
      },
      {
        path: 'analytics',
        element: <Analytics />,
      },
    ],
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <RouterProvider router={router} />
        </PersistGate>
    </Provider>
  </React.StrictMode>
);

// vim: set ts=2 sw=2
