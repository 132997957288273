import { configureStore } from '@reduxjs/toolkit'
import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
    persistReducer,
    persistStore,
} from 'redux-persist'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import { combineReducers } from "redux";

import currentWorkoutReducer from '../features/excercises/currentWorkoutSlice'
import historyReducer from '../features/history/historySlice'
import createCompressor from 'redux-persist-transform-compress'
import KvStorage from './kvstorage'


const logMiddleware = storeAPI => next => action => {
    /*
    console.log('action: ', action)
    console.log('state: ', storeAPI.getState())
    */
    // Otherwise, it's a normal action - send it onwards
    return next(action)
}

const compressor = createCompressor()

const persistConfig = {
    key: 'root',
    version: 1,
    storage: new KvStorage('https://kvstorage.net'),
    stateReconciler: hardSet,
    debug: true,
    throttle: 1000,
    transforms: [compressor],
};

const reducers = combineReducers({
    currentWorkout: currentWorkoutReducer,
    history: historyReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(logMiddleware)
    },
});


export const persistor = persistStore(store);
